import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/Hero/Hero"
import CategoryNav from "../../components/CategoryNav/CategoryNav"

import { formatDateTime } from "../../utils/date-time"

const ArticleLanding = ({ data, pageContext }) => {
  const { site, index, categories, articles } = data

  let currentArticles = articles.nodes
  let canonicalData = {}
  if (pageContext.categoryId) {
    currentArticles = articles.nodes.filter(
      article => article.category[0].id === pageContext.categoryId
    )
    canonicalData = {
      rel: "canonical",
      href: `${site.siteMetadata.siteUrl}/${index.uri}/`,
    }
  }

  const categoryOptions = [
    {
      id: index.id,
      title: "All",
      uri: index.uri,
    },
    ...categories.nodes,
  ]

  return (
    <Layout>
      <Seo
        title={index.metaTitle || index.heading}
        description={index.metaDescription}
        link={[canonicalData]}
      />
      <Hero
        heading={index.heading}
        headingTranslated={index.headingTranslated}
        subheading={index.subheading}
        text={index.text}
        image={index.image[0]}
      />
      <section className="py-28 md:py-56">
        <div className="container">
          <CategoryNav categories={categoryOptions} />
          {currentArticles.length > 0 && (
            <div className="grid grid-cols-12 gap-y-15 lg:gap-x-10 lg:gap-y-20">
              {currentArticles.map(item => {
                const { id, uri, image, category, postDate, title, summary } =
                  item

                return (
                  <article
                    className="col-span-12 lg:col-span-6 xl:col-span-4"
                    key={id}
                  >
                    <Link className="group" to={`/${uri}/`}>
                      <div className="relative mb-4">
                        <div className="aspect-w-3 aspect-h-2">
                          <GatsbyImage
                            className="rounded-br-3xl overflow-hidden"
                            image={getImage(image[0].localFile)}
                            alt={image[0].title}
                            style={{ position: "absolute" }} // To override Gatsby Image for aspect ratio
                          />
                        </div>
                        <div className="px-4 p-2 rounded-full absolute top-5 right-5 bg-green text-xs leading-none uppercase tracking-wider font-bold text-white sm:text-sm">
                          {category[0].title}
                        </div>
                      </div>
                      <div className="lg:pr-10">
                        <div className="text-black text-opacity-75 mb-4">
                          {formatDateTime(postDate)}
                        </div>
                        <h4 className="text-xl lg:text-2xl group-hover:text-green font-semibold mb-3">
                          {title}
                        </h4>
                        <div className="text-sm lg:text-base">{summary}</div>
                      </div>
                    </Link>
                  </article>
                )
              })}
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ArticleLanding

export const pageQuery = graphql`
  query ArticleLandingQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    index: craftArticlesIndexArticlesIndexEntry {
      id
      metaTitle
      metaDescription
      heading: title
      headingTranslated: translatedTitle
      subheading: textPrimary
      text: textSecondary
      uri
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
    }
    categories: allCraftArticlesCategoriesDefaultEntry {
      nodes {
        id
        title
        uri
      }
    }
    articles: allCraftArticlesDefaultEntry {
      nodes {
        id
        title
        summary
        uri
        postDate
        category: articleCategory {
          id
          title
        }
        image {
          ... on Craft_images_Asset {
            ...imageFragment
          }
        }
      }
    }
  }
`
