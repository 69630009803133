import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Hero = ({ heading, headingTranslated, subheading, text, image }) => (
  <div className="-mb-12 md:-mb-24 pt-6 md:pt-0 relative">
    <div className="mb-12 md:mb-24 bg-green absolute inset-0"></div>
    <div className="container relative">
      <div className="md:grid md:grid-cols-12 md:gap-5 md:items-end">
        <div className="col-span-6 lg:col-span-5">
          <div className="text-white mb-12 md:pb-12 md:mb-24 lg:pb-20 xl:pb-32">
            {heading && <h1 className="text-4xl lg:text-6xl">{heading}</h1>}
            {headingTranslated && (
              <h2 className="text-4xl opacity-60 lg:text-6xl">
                {headingTranslated}
              </h2>
            )}
            {(subheading || text) && (
              <div className="w-96 max-w-full mt-4 lg:mt-8">
                {subheading && (
                  <h3 className="text-xl lg:text-2xl">{subheading}</h3>
                )}
                {text && <p className="lg:text-sm mt-5">{text}</p>}
              </div>
            )}
          </div>
        </div>
        <div className="col-span-6 lg:col-span-7">
          <div className="-mr-ocs lg:mr-0">
            <div className="aspect-w-2 aspect-h-1 bg-black rounded-bl-3xl lg:rounded-bl-none lg:rounded-br-3xl overflow-hidden relative">
              {image && (
                <GatsbyImage
                  className="w-full h-full"
                  image={getImage(image.localFile)}
                  alt={image.title}
                  style={{ position: "absolute" }} // To override Gatsby Image for aspect ratio
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Hero.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Hero
