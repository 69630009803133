var MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

/**
 * Converts a valid date-time string into a formatted date string.
 *
 * @param { string } dateTime
 * @returns { string } date string formatted like "28 April 1993"
 */
export const formatDateTime = dateTime => {
  const date = new Date(dateTime)

  if (!date) {
    const error = { code: 400, message: `cannot convert ${dateTime} to a date` }
    throw error
  }

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return `${day} ${MONTH_NAMES[monthIndex]} ${year}`
}
