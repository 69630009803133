import React from "react"
import { Link } from "gatsby"

const CategoryNav = ({ categories }) => {
  if (categories.length < 2) {
    return null
  }

  return (
    <ul className="flex flex-wrap items-center gap-4 mb-14 text-xs leading-none uppercase tracking-wider font-bold sm:text-sm sm:gap-6">
      {categories.map(category => (
        <li key={category.id}>
          <Link
            to={`/${category.uri}/`}
            className="inline-block"
            activeClassName="bg-green text-white px-4 p-2 rounded-full"
            state={{ retainPrevScrollPosition: true }}
          >
            {category.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default CategoryNav
